// 2019-10-20 15:40:20
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";

const closer = (a, b, needle) => {
  return Math.abs(a - needle) <= Math.abs(b - needle);
};

const BUFFER = 10;

const Page = styled.div`
  padding-bottom: ${BUFFER}px;
`;

const Runaway = () => {
  useEffect(() => Prism.highlightAll());

  const runawayRef = useRef();
  const pageRef = useRef();
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const mousemove = ({ clientX, clientY }) => {
      if (!runawayRef.current || !pageRef.current) {
        return;
      }
      const pageRect = pageRef.current.getBoundingClientRect();
      const {
        top,
        right,
        bottom,
        left,
      } = runawayRef.current.getBoundingClientRect();

      let miniOffsetX = 0;
      let miniOffsetY = 0;

      const miniLeft = () => left + miniOffsetX - BUFFER;
      const miniRight = () => right + miniOffsetX + BUFFER;
      const miniTop = () => top + miniOffsetY - BUFFER;
      const miniBottom = () => bottom + miniOffsetY + BUFFER;

      const overlappingX = () =>
        miniLeft() <= clientX && clientX <= miniRight();
      const overlappingY = () =>
        miniTop() <= clientY && clientY <= miniBottom();

      const penetrationX = () =>
        overlappingX()
          ? Math.min(
              Math.abs(miniLeft() - clientX),
              Math.abs(miniRight() - clientX),
            )
          : 0;
      const penetrationY = () =>
        overlappingY()
          ? Math.min(
              Math.abs(miniTop() - clientY),
              Math.abs(miniBottom() - clientY),
            )
          : 0;

      let i = 0;
      while (overlappingX() && overlappingY()) {
        const SCHOOCH = 1;
        if (penetrationX() >= penetrationY()) {
          miniOffsetX +=
            SCHOOCH * (closer(miniLeft(), miniRight(), clientX) ? 1 : -1);
        } else {
          miniOffsetY +=
            SCHOOCH * (closer(miniTop(), miniBottom(), clientY) ? 1 : -1);
        }
        i++;
        if (i >= 100) {
          break;
        }
      }

      // keep in bounds
      miniOffsetX += Math.max(pageRect.left - miniLeft(), 0);
      miniOffsetX -= Math.max(miniRight() - pageRect.right, 0);
      miniOffsetY += Math.max(pageRect.top - miniTop(), 0);
      miniOffsetY -= Math.max(miniBottom() - pageRect.bottom, 0);

      setOffsetX(offsetX + miniOffsetX);
      setOffsetY(offsetY + miniOffsetY);
    };

    document.addEventListener("mousemove", mousemove);
    return () => document.removeEventListener("mousemove", mousemove);
  }, [offsetX, offsetY]);

  return (
    <Layout>
      <Page ref={pageRef}>
        <SEO title="Runaway"></SEO>
        <p>
          You can (ab)use CSS to move elements around relative to their normal
          positions with
        </p>

        <pre>
          <code class="language-css">{`position: relative;
left: 100px;
right: 100px;`}</code>
        </pre>

        <p>..And you can observe mouse movements with</p>

        <pre>
          <code class="language-javascript">{`document.addEventListener(
  "onmousemove",
  ({ clientX, clientY }) => {}
)`}</code>
        </pre>
        <p>
          Combining these, we can make a link move depending on the cursor
          position. Click this link to continue:{" "}
          <a
            ref={runawayRef}
            style={{
              position: "relative",
              left: offsetX,
              top: offsetY,
            }}
            href="/highlight"
          >
            Next
          </a>
        </p>
      </Page>
    </Layout>
  );
};

export default Runaway;
